import * as React from "react";

const SVGTranslation = (props) => (
	<svg
		id="Layer_2"
		data-name="Layer 2"
		xmlns="http://www.w3.org/2000/svg"
		width={138.51}
		height={127.637}
		viewBox="0 0 138.51 127.637"
		{...props}
	>
		<g id="Layer_1" data-name="Layer 1">
			<path
				id="Path_61"
				data-name="Path 61"
				d="M65,74.06v36.6H49v13.48l-18.7-11-4.22-2.49H2V47.71H38.62A26.34,26.34,0,0,1,65,74.06Z"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={4}
			/>
			<path
				id="Path_62"
				data-name="Path 62"
				d="M73.61,28.34V65h16V78.43l18.7-11,4.2-2.43h24V2H99.94A26.33,26.33,0,0,0,73.61,28.34Z"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={4}
			/>
			<text
				id="A"
				transform="translate(19.42 93.86)"
				fill="#c157a1"
				fontSize={36.9}
				fontFamily="SegoeUI-Semibold, Segoe UI"
				fontWeight={600}
			>
				<tspan x={0} y={0}>
					{"A"}
				</tspan>
			</text>
			<path
				id="Path_63"
				data-name="Path 63"
				d="M113.71,28.13a23.09,23.09,0,0,1-21.91,18"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={3}
			/>
			<path
				id="Path_64"
				data-name="Path 64"
				d="M121.78,28.13H100.22A23,23,0,0,0,121.78,45.5"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={3}
			/>
			<line
				id="Line_29"
				data-name="Line 29"
				y1={5.48}
				transform="translate(107.53 23.73)"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={3}
			/>
			<path
				id="Path_65"
				data-name="Path 65"
				d="M91.8,28.13h27.55"
				fill="none"
				stroke="#c157a1"
				strokeMiterlimit={10}
				strokeWidth={3}
			/>
		</g>
	</svg>
);

export default SVGTranslation;
