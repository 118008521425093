import * as React from "react";

const SVGCopycoaching = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={95}
		height={85}
		viewBox="0 0 95 85"
		{...props}
	>
		<g id="Coaching_icon" data-name="Coaching icon" transform="translate(0 2)">
			<g id="Group_191" data-name="Group 191" transform="translate(0 9)">
				<g
					id="Intersection_2"
					data-name="Intersection 2"
					transform="translate(59 74) rotate(180)"
					fill="none"
				>
					<path
						d="M0,6.472A61.448,61.448,0,0,1,.339,0H58.661A61.449,61.449,0,0,1,59,6.472C59,29.959,45.792,49,29.5,49S0,29.959,0,6.472Z"
						stroke="none"
					/>
					<path
						d="M 4.052619934082031 4 C 4.017601013183594 4.822589874267578 4 5.647605895996094 4 6.471511840820312 C 4 11.8315315246582 4.725543975830078 17.02224349975586 6.156490325927734 21.89947700500488 C 7.52130126953125 26.55133247375488 9.462821960449219 30.71132278442383 11.92710113525391 34.26390075683594 C 14.31645584106445 37.70846557617188 17.06615447998047 40.3929328918457 20.09983444213867 42.24273300170898 C 23.10035705566406 44.07232284545898 26.26311111450195 45 29.5002326965332 45 C 32.73723602294922 45 35.89987945556641 44.07233428955078 38.90033340454102 42.24275588989258 C 41.93395614624023 40.3929557800293 44.68360900878906 37.70848846435547 47.07294464111328 34.26392364501953 C 49.53720092773438 30.71133422851562 51.47871017456055 26.55133247375488 52.84352111816406 21.89947700500488 C 54.27445602416992 17.02224349975586 55 11.83152389526367 55 6.471511840820312 C 55 5.647632598876953 54.98239517211914 4.822586059570312 54.94738006591797 4 L 4.052619934082031 4 M 0.33935546875 0 L 58.6606330871582 0 C 58.88417816162109 2.110488891601562 59 4.270942687988281 59 6.471511840820312 C 59 29.9594898223877 45.79230117797852 49 29.5002326965332 49 C 13.20768737792969 49 0 29.9594898223877 0 6.471511840820312 C 0 4.270942687988281 0.1158103942871094 2.110488891601562 0.33935546875 0 Z"
						stroke="none"
						fill="#c157a1"
					/>
				</g>
				<g
					id="Ellipse_5"
					data-name="Ellipse 5"
					transform="translate(14 -2)"
					fill="none"
					stroke="#c157a1"
					strokeWidth={4}
				>
					<ellipse cx={15.5} cy={15} rx={15.5} ry={15} stroke="none" />
					<ellipse cx={15.5} cy={15} rx={13.5} ry={13} fill="none" />
				</g>
			</g>
			<g id="Group_192" data-name="Group 192" transform="translate(36)">
				<g
					id="Intersection_2-2"
					data-name="Intersection 2"
					transform="translate(59 74) rotate(180)"
					fill="none"
				>
					<path
						d="M0,6.472A61.448,61.448,0,0,1,.339,0H58.661A61.449,61.449,0,0,1,59,6.472C59,29.959,45.792,49,29.5,49S0,29.959,0,6.472Z"
						stroke="none"
					/>
					<path
						d="M 4.052619934082031 4 C 4.017601013183594 4.822589874267578 4 5.647605895996094 4 6.471511840820312 C 4 11.8315315246582 4.725543975830078 17.02224349975586 6.156490325927734 21.89947700500488 C 7.52130126953125 26.55133247375488 9.462821960449219 30.71132278442383 11.92710113525391 34.26390075683594 C 14.31645584106445 37.70846557617188 17.06615447998047 40.3929328918457 20.09983444213867 42.24273300170898 C 23.10035705566406 44.07232284545898 26.26311111450195 45 29.5002326965332 45 C 32.73723602294922 45 35.89987945556641 44.07233428955078 38.90033340454102 42.24275588989258 C 41.93395614624023 40.3929557800293 44.68360900878906 37.70848846435547 47.07294464111328 34.26392364501953 C 49.53720092773438 30.71133422851562 51.47871017456055 26.55133247375488 52.84352111816406 21.89947700500488 C 54.27445602416992 17.02224349975586 55 11.83152389526367 55 6.471511840820312 C 55 5.647632598876953 54.98239517211914 4.822586059570312 54.94738006591797 4 L 4.052619934082031 4 M 0.33935546875 0 L 58.6606330871582 0 C 58.88417816162109 2.110488891601562 59 4.270942687988281 59 6.471511840820312 C 59 29.9594898223877 45.79230117797852 49 29.5002326965332 49 C 13.20768737792969 49 0 29.9594898223877 0 6.471511840820312 C 0 4.270942687988281 0.1158103942871094 2.110488891601562 0.33935546875 0 Z"
						stroke="none"
						fill="#c157a1"
					/>
				</g>
				<g
					id="Ellipse_5-2"
					data-name="Ellipse 5"
					transform="translate(14 -2)"
					fill="none"
					stroke="#c157a1"
					strokeWidth={4}
				>
					<ellipse cx={15.5} cy={15} rx={15.5} ry={15} stroke="none" />
					<ellipse cx={15.5} cy={15} rx={13.5} ry={13} fill="none" />
				</g>
			</g>
		</g>
	</svg>
);

export default SVGCopycoaching;
