import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import Navigation from "./navigation";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ title, description, image, imgAlt }) => {
	return (
		<>
			<div Tag="section" className="hero-services mb-0 position-relative  ">
				<div
					style={{
						background:
							"transparent linear-gradient(108deg, #FBCAEC7D 0%, #FF96DC7A 50%, #F2C8E5 100%) 0% 0% no-repeat padding-box",
						opacity: "1",
						zIndex: "-999",
					}}
					className="position-absolute w-100 h-90"
				>
					<div
						style={{
							width: "5px",
							height: "100px",
							backgroundColor: "#8D8D8D",
						}}
						className=" d-none d-md-block d-lg-none top-100 hero-line translate-middle position-absolute"
					></div>
					<div
						style={{
							width: "3px",
							height: "50px",
							backgroundColor: "#8D8D8D",
						}}
						className="  d-md-none top-100 hero-line translate-middle position-absolute"
					></div>
				</div>
				<Navigation background="none" />
				<Container className="pt-md-4">
					<Row className=" pt-3 px-3  align-items-center">
						<Col
							className="text-center d-none d-md-block mb-md-5"
							lg={6}
							xl={5}
						>
							<div
								style={{ maxWidth: "25rem", height: "20rem" }}
								className="bg-background position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "25rem", height: "20rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
						</Col>
						<Col xl={7} lg={6} className=" pb-8 ps-lg-3">
							<h1 className="mb-4" id="services-hero-title">
								{title}
							</h1>
							<div
								style={{ maxWidth: "12rem", height: "10rem" }}
								className="bg-background d-md-none position-relative mb-4
								 mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "12rem", height: "10rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
							<p className=" mb-md-4 pb-md-4  pb-lg-0 mb-lg-0 fs-4 text-center text-md-start fw-light">
								{description}
							</p>
						</Col>
					</Row>
				</Container>
			</div>
			<Container>
				<Row>
					<Col xl={{ span: 7, offset: 5 }} lg={{ span: 6, offset: 6 }}>
						<Row
							className="mt-0 pt-2 justify-content-center justify-content-lg-start
						"
						>
							<Col md={6} xl={5}>
								<Button
									className="cta-btn w-xl-90 w-xl-100 w-100 py-2 py-md-3"
									variant="secondary"
									as={Link}
									to="/contact"
								>
									Contact
								</Button>
							</Col>

							<Col md={6} xl={5}>
								<Button
									className="cta-btn w-xl-90 w-100  d-none d-md-block py-3"
									as={Link}
									to="/services"
									variant="outline-secondary"
								>
									Our Services
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Hero;
