import React from "react";
import { Helmet } from "react-helmet";

const LocalBusinessSchema = () => {
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://id.arc-wts.co.uk",
		name: "ARC Writing and Translation Services",
		legalName: "AMG TRANSLATIONS LIMITED",
		description:
			"The company’s aim is to provide copywriting services to businesses in London, as well as nationally and internationally.",
		url: "https://arc-wts.co.uk",
		priceRange: "$$",
		email: "info@arc-wts.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		foundingLocation: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		slogan: "Captivating copy, ghostwriting and spot-on translations",
		telephone: "+441273256059",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN22 8PW",
			streetAddress: "Archer House, Britland Estate, Northbourne Rd",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "50.82621335426978",
			longitude: "-0.16007580000890953",
		},
		hasMap: "https://goo.gl/maps/",
		openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Antoinette Chappell",
			id: "https://arc-wts.co.uk/about#AntoinetteChappell",
			url: "https://arc-wts.co.uk/about",
			description:
				"I founded ARC Writing and Translation Services which is based in London to meet the needs of soloprenuers, entrepreneurs and SMEs who need help with their messaging to generate leads, drive website traffic and create brand awareness by using engaging, persuasive copy for their website copy and marketing materials. As a qualified, certified translator I am a member of the Institute of Translation and Interpreting and am able to source experienced, qualified translators and manage translation projects for my clients.",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://arc-wts.co.uk/contact-us",
			url: "https://arc-wts.co.uk/contact-us",
			image: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/ldYxqzcA-scaled-1-2048x1612-1.jpeg`,
			name: "Antoinette Chappell",
			telephone: "+441273256059",
			contactType: "Sales",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Hove",
					sameAs: "https://en.wikipedia.org/wiki/Hove",
				},
			],
		},
		logo: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/ARC-logo.png`,
		image: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/ldYxqzcA-scaled-1-2048x1612-1.jpeg`,
		sameAs: [
			"https://www.facebook.com/ARCCopywritingTranslations/",
			"https://www.linkedin.com/in/antoinette-chappell-b0a83812a/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
