import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Services from "../components/services";
import InfoPanel from "../components/info-panel";
import CtaBanner from "../components/cta-banner";
import Reviews from "../components/reviews";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Row, Container } from "react-bootstrap";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			infoImg: wpMediaItem(title: { eq: "Info-Panel-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			certImg: wpMediaItem(title: { eq: "Certification-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const infoImg = data.infoImg?.localFile.childImageSharp.gatsbyImageData;
	const certImg = data.certImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Copywriting and Translation Agency in London | ARC"
				description="With nearly 15 yrs experience in writing/translating corporate documents, I help reach dream clients with persuasive copy that boosts sales."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Copywriting and Translation Agency in London | ARC",
					description:
						"With nearly 15 yrs experience in writing/translating corporate documents, I help reach dream clients with persuasive copy that boosts sales.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/CNoGBiUg-scaled-1.jpg`,
							width: 2560,
							height: 2316,
							alt: "Copywriting and Translation agency in London",
						},
					],
				}}
			/>
			<Hero
				title="Bridging the gap between your brand and your audience with captivating copy, discreet ghostwriting and trusted translations"
				description="Are you looking for an experienced writer you can trust to elevate your brand?"
				image={heroImg}
				imgAlt={data.heroImg.altText}
			/>
			<section id="Services" className="py-5 py-md-8 py-lg-9">
				<Services />
			</section>
			<section className="bg-dark-background">
				<Container>
					<Row className="justify-content-center">
						<Col className="py-5" lg={6}>
							{" "}
							<div className="iframe-container-new ">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/IwQVV9aN3ec?si=hjS2n2IpEs5l3V6b"
									title="How to become a copywriter without any previous experience"
									frameborder="0"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="mb-6 ">
				<InfoPanel
					title="Why choose ARC?"
					text={
						<>
							<p>
								With over 15 years of professional experience writing and
								translating a variety of corporate documents, I can help you
								reach your dream clients and grow by writing captivating,
								persuasive copy that resonates with your audience or help you
								author a non-fiction business book that positions you as a
								thought leader by acting as your ghostwriter.
							</p>
							<p>
								In 2023, I achieved a lifelong ambition to write a book. What I
								learned along the way is how much I enjoy the process of writing
								a book. Right from the planning stage to creating a narrative
								and using storytelling to express concepts and methods in a
								non-fiction context. I love every step. I now help other
								business owners and coaches boost their brands by acting as
								their personal ghostwriter so that they can author their own
								books.
							</p>
							<p>
								If you have always dreamed of writing a non-fiction book to help
								other people who are just starting out in your industry or you
								have a specific methodology and insights to share but don’t have
								the time to write it - or maybe writing just isn’t your thing -
								I can help you by ghostwriting a book for you that boosts your
								brand, creates a passive income stream and leaves a lasting
								legacy.
							</p>
							<p>
								I am passionate about words and how they fit on a page, whether
								virtual or paper. Simply put, I love words and helping people
								communicate their message clearly to their target audience.
							</p>
							<p>
								Geography is not an issue for me as I have clients who use my
								writing and translation services in London, Kent, Surrey and
								Sussex. I can discuss your needs online via Zoom, or in person
								if you prefer.
							</p>
						</>
					}
					image={infoImg}
					imgAlt={data.infoImg.altText}
				/>
			</section>
			<section>
				<Reviews />
			</section>

			<section>
				<Container>
					<Row className="justify-content-center">
						<Col className="pt-5" lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/rCWcATAhef0"
									title="A conversation with Jason Cornes"
									frameborder="0"
									allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-6 pt-lg-4 py-xl-7 text-center">
				<GatsbyImage
					image={certImg}
					style={{ maxWidth: "200px" }}
					alt={data.certImg.altText}
				/>
			</section>
			<section>
				<CtaBanner
					headline={
						<>
							I'd love to hear more about your
							<br className="d-none d-md-block" /> needs, and how I can help.
						</>
					}
					bgColor="dark-background"
					btnTxt="Contact"
					btnLink="/contact"
				/>
			</section>
		</Layout>
	);
};

export default IndexPage;
